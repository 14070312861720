@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "GILROYBOLD";
    src: url("../public/fonts/Gilroy-Bold.ttf");
  }

  @font-face {
    font-family: "GILROY";
    src: url("../public/fonts/Gilroy-Regular.ttf");
  }


  /* Add this CSS in your stylesheet or <style> tag in your component */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 260px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the icon */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

